import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Toolbar,
  Typography,
  Grid,
  Tooltip,
  Box,
  Tab,
  DialogTitle,
  DialogActions,
  IconButton,
} from "@mui/material";
import BuildIcon from "@mui/icons-material/Build";
import CodeIcon from "@mui/icons-material/Code";
import AssistantTraining from "./AssistantTrain";
import CustomizationComponent from "./AssistantCustomization";
import IntegrationComponent from "./AssistantIntegration";
import { useNavigate, useLocation } from "react-router-dom";
import PhoneIcon from "@mui/icons-material/Phone";
import SaveIcon from "@mui/icons-material/Save";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ErrorBoundary from "../../components/ErrorBoundary";
import TestAssistantForm from "../../components/TestAssistantForm";
import PsychologyIcon from "@mui/icons-material/Psychology";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import WarningIcon from "@mui/icons-material/Warning";
import CloseIcon from "@mui/icons-material/Close";
import {
  APP_PAGES,
  CLEVERTAP_EVENT,
  LOCAL_STORAGE,
  ROUTE_PATH,
} from "../../data/configs/constants";
import {
  getAssistant,
  updateAssistant,
  getAllActions,
  getMentionList,
} from "../../data/store/assistantsSlice";
import { cleverTap } from "../../data/configs/clevertap";
import { useDispatch, useSelector } from "react-redux";
import {
  getDataFromLocalStorage,
  log,
  setDataInLocalStorage,
} from "../../data/configs/utils";
import { updatePageState } from "../../data/store/pageSlice";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { enqueueSnackbar } from "notistack";

const AssistantSetup = () => {
  const { pathname, state } = useLocation();
  const [selectedTab, setSelectedTab] = useState("train");
  const [preBot, setPreBot] = useState(false);
  const handleCloseDrawer = () => setShowDrawer(false);
  const workspaceId = useSelector(
    (state) => state.authDetails?.user?.workspace
  );
  const user = useSelector((state) => state.authDetails.user);
  const installedAppNameArray = user?.installedApps;
  const [initialBookAMeetingTemplate, setInititalBookAMeetingTemplate] =
    useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [phoneNumbers1, setPhoneNumbers1] = useState([]);
  const [elevenlabsGeneratedVoices, setElevenlabsGeneratedVoices] = useState(
    []
  );

  const [inititalWebhookUrls, setInititalWebhookUrls] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [isSaveClicked, setIsSavedClicked] = useState(false);
  const [assistant, setAssistant] = useState({});
  const [actionIds, setActionIds] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allActionData, setAllActionData] = useState([]);
  const [fromOnboarding, setFromOnboarding] = useState(false);
  const [abortSaving, setAbortSaving] = useState(false);
  const [mentionList, setMentionList] = useState([]);
  const defaultFormData = {
    templateName: "",
    calendarSpecificInfo: {
      calendar: "cal",
      eventId: "",
      accountUserId: "",
      meetingLocation: {
        type: "",
        identifier: "",
      },
      attendeeTimeZone: "",
    },
    communicationChannelInfo: {
      channel: "sms",
      meetingLink: "",
      messageBody:
        "Hi from VoiceGenie's service! Please use the link below to schedule your meeting. Reply STOP to unsubscribe. Thank you!",
      consentMessage: `We'll send you a link via SMS to book a meeting with us. Reply 'STOP' to the message to opt-out,Would you like to receive the SMS ?`,
      serviceInfo: {
        // phoneNumberId: "",
        provider: "twilio",
        // accountId: "",
        // authToken: ""
      },
    },
    title: "",
    description: "",
    meetingGuests: [],
  };

  const [formData, setFormData] = useState(defaultFormData);
  const [formDataToUpdate, setFormDataToUpdate] = useState({
    id: "",
    formData: defaultFormData,
    triggeringPrompts: ["I'm interested in scheduling a meeting."],
  });
  const [saveChanges, setSaveChanges] = useState({
    transferToAgent: false,
    webhook: false,
    smsBooking: false,
  });
  const [comForceAllowDialog, setComForceAllowDialog] = useState(false);
  const [comCorrection, setComCorrection] = useState({
    msg: "",
    comWarnActive: false,
  });

  const [elevenlabsClonedVoiceAgreement, setElevenlabsClonedVoiceAgreement] =
    useState(false);

  const handleSaveChangesUpdate = (key, value) => {
    setSaveChanges((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleButtonClick = (tab) => {
    setSelectedTab(tab);
  };
  const handleTalkToAgentClick = () => {
    cleverTap.track(CLEVERTAP_EVENT.BUTTON_CLICK, {
      workspaceId,
      buttonName: "Test Your Assistant",
      onboarding: pathname === ROUTE_PATH.UPDATE_AND_TEST_ASSISTANT,
    });
    setDialogData({
      showTestAssistant: true,
      testAssistant: {},
    });
  };
  const handleCOMForceDialogToggle = () => {
    setComForceAllowDialog(!comForceAllowDialog);
  };

  const handleCOMForceSave = () => {
    setComForceAllowDialog(false);
    handleSaveChanges(false);
  };

  const handleSaveChanges = (forceSave = true, params = {}) => {
    if (
      !abortSaving &&
      !elevenlabsClonedVoiceAgreement &&
      assistant?.ssml_data?.tts_provider === "elevenlabs" &&
      (assistant?.ssml_data?.category === "cloned" ||
        assistant?.ssml_data?.category === "professional")
    ) {
      enqueueSnackbar("Please agree to the consent to proceed!", {
        variant: "warning",
      });
      return;
    }
    const additionalParams = forceSave ? {} : { comForceSave: true };
    // Check if tts_provider is elevenlabs, and append the model conditionally
    const updatedAssistant = {
      ...assistant,
      ...params,
      ...additionalParams,
      ssml_data: {
        ...assistant.ssml_data,
        model:
          assistant?.ssml_data?.tts_provider === "elevenlabs"
            ? "eleven_turbo_v2_5"
            : undefined, // Do not send the model if the provider is not elevenlabs
      },
    };
    // dispatch(updateAssistant({ ...assistant, ...params, ...additionalParams }))
    dispatch(updateAssistant(updatedAssistant))
      .unwrap()
      .then((res) => {
        if (
          res?.data?.updatedAssistant?.ssml_data &&
          res?.data?.updatedAssistant?.ssml_data?.tts_provider ===
            "elevenlabs" &&
          (res?.data?.updatedAssistant?.ssml_data?.category === "cloned" ||
            res?.data?.updatedAssistant?.ssml_data?.category === "professional")
        ) {
          setAbortSaving(true);
        }
        setIsSavedClicked(true);
        setIsChanged(false);
        if (showDrawer) handleCloseDrawer();
        if (!forceSave || comCorrection.msg)
          setComCorrection({
            msg: "",
            comWarnActive: false,
          });
        if (preBot) {
          handleTalkToAgentClick();
        }
      })
      .catch((err) => {
        if (err.data.data.com.status === false && err.data.data.com.message) {
          setSelectedTab("train");
          setComForceAllowDialog(true);
          setComCorrection({
            msg: err.data.data.com.message,
            comWarnActive: true,
          });
        }
      });
  };

  const handleCloseDialoge = (e, reason) => {
    if (reason !== "backdropClick") setDialogData({});
  };
  const handleChange = (changedValue) => {
    setAssistant((prev) => ({
      ...prev,
      ...changedValue,
    }));
    if (!isChanged) setIsChanged(true);
  };

  const getAllActionData = () => {
    if (allActionData?.length === 0) {
      dispatch(getAllActions())
        .unwrap()
        .then((res) => {
          setAllActionData(res.data.actions);
        })
        .catch((err) => {
          log("ERR-getAllActions", err);
        });
    }
  };

  const getMentionListDetails = () => {
    dispatch(getMentionList())
      .unwrap()
      .then((res) => {
        setMentionList(res.data.allCols);
      })
      .catch((err) => {
        log("ERR-getMentionList", err);
      });
  };
  useEffect(() => {
    dispatch(
      updatePageState({
        currentPage: APP_PAGES.EDIT_ASSISTANT,
        currentActiveModule: APP_PAGES.ASSISTANTS.PAGE,
      })
    );
  }, [dispatch, navigate]);

  useEffect(() => {
    setDataInLocalStorage(LOCAL_STORAGE.ELEVEN_LABS_CLONED_AGREEMENT, false);
    getMentionListDetails();
    getAllActionData(assistant.assistantId);
    if (pathname === "/onboarding/updateAssistant") {
      setFromOnboarding(true);
    }
  }, []);

  const tabs = [
    {
      label: "TRAIN",
      value: "train",
      icon: <PsychologyIcon />,
      component: (
        <AssistantTraining
          comCorrection={comCorrection}
          setComCorrection={setComCorrection}
          mentionList={mentionList}
          handleChange={handleChange}
          assistant={assistant}
          isSaveClicked={isSaveClicked}
          setIsSavedClicked={setIsSavedClicked}
          fromOnboarding={fromOnboarding}
          preBot={preBot}
          handleSaveChanges={handleSaveChanges}
        />
      ),
    },
    {
      label: "CUSTOMIZATION",
      value: "customization",
      icon: <BuildIcon />,
      component: (
        <CustomizationComponent
          setElevenlabsGeneratedVoices={setElevenlabsGeneratedVoices}
          elevenlabsGeneratedVoices={elevenlabsGeneratedVoices}
          mentionList={mentionList}
          handleChange={handleChange}
          assistant={assistant}
          abortSaving={abortSaving}
          setIsChanged={setIsChanged}
          elevenlabsClonedVoiceAgreement={elevenlabsClonedVoiceAgreement}
          setElevenlabsClonedVoiceAgreement={setElevenlabsClonedVoiceAgreement}
        />
      ),
    },
    {
      label: "ACTIONS",
      value: "integration",
      icon: <CodeIcon />,
      component: (
        <IntegrationComponent
          formDataToUpdate={formDataToUpdate}
          setFormDataToUpdate={setFormDataToUpdate}
          formData={formData}
          setFormData={setFormData}
          defaultFormData={defaultFormData}
          phoneNumbers1={phoneNumbers1}
          setPhoneNumbers1={setPhoneNumbers1}
          initialBookAMeetingTemplate={initialBookAMeetingTemplate}
          setInititalBookAMeetingTemplate={setInititalBookAMeetingTemplate}
          saveChanges={saveChanges}
          setSaveChanges={setSaveChanges}
          handleSaveChangesUpdate={handleSaveChangesUpdate}
          handleSaveChanges={handleSaveChanges}
          setAllActionData={setAllActionData}
          allActionData={allActionData}
          handleChange={handleChange}
          assistant={assistant}
          inititalWebhookUrls={inititalWebhookUrls}
          setInititalWebhookUrls={setInititalWebhookUrls}
          actionIds={actionIds}
          setActionIds={setActionIds}
        />
      ),
    },
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const handleError = () => {
      if (pathname === ROUTE_PATH.UPDATE_AND_TEST_ASSISTANT)
        navigate(ROUTE_PATH.CREATE_FIRST_ASSISTANT);
      else navigate(ROUTE_PATH.ASSISTANTS);
    };

    if (queryParams.has("id")) {
      dispatch(
        getAssistant({
          assistantId: queryParams.get("id"),
        })
      )
        .unwrap()
        .then((res) => {
          if (res?.data?.assistant?.assistantId) {
            setAssistant(res.data.assistant);
            if (state && state.isPreBot) {
              setPreBot(true);
            }
          } else throw new Error();

          if (
            res?.data?.assistant?.ssml_data &&
            res?.data?.assistant?.ssml_data?.tts_provider === "elevenlabs" &&
            (res?.data?.assistant?.ssml_data?.category === "cloned" ||
              res?.data?.assistant?.ssml_data?.category === "professional")
          ) {
            setAbortSaving(true);
          }
        })
        .catch(handleError);
    } else {
      handleError();
    }
  }, [dispatch, navigate, pathname]);

  useEffect(() => {
    if (state && state.stepper) {
      setSelectedTab(state.stepper);
      window.history.replaceState(null, "");
    } else {
      setSelectedTab("train");
    }
  }, [state]);

  return (
    <>
      <Card
        sx={{
          width: "100%",
          minHeight: "832px",
          boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Grid
          item
          xs={12}
          marginBlock={1.5}
          marginInline={5}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {assistant?.name || "THE BOT"}
          </Typography>

          {isChanged ? (
            <Tooltip
              title={
                !assistant.goal?.trim()
                  ? "Assistant's Goal is required to save"
                  : assistant.script?.trim() === "<p><br></p>" ||
                    !assistant.script?.trim()
                  ? "Assistant's Script is required to save"
                  : assistant.callOpeningMessage?.trim() === "<p><br></p>" ||
                    !assistant.callOpeningMessage?.trim()
                  ? "Assistant's Call Opening Message is required to save"
                  : ""
              }
            >
              <span>
                <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  startIcon={<SaveIcon />}
                  onClick={handleSaveChanges}
                  disabled={
                    assistant.script?.trim() === "<p><br></p>" ||
                    !assistant.script?.trim() ||
                    !assistant.callOpeningMessage?.trim() ||
                    !assistant.goal?.trim() ||
                    assistant.callOpeningMessage?.trim() === "<p><br></p>"
                  }
                  disableElevation
                >
                  Save
                </Button>
              </span>
            </Tooltip>
          ) : (
            <Tooltip
              title={
                !assistant.goal?.trim()
                  ? "Assistant's Goal is required to test"
                  : assistant.script?.trim() === "<p><br></p>" ||
                    !assistant.script?.trim()
                  ? "Assistant's Script is required to test"
                  : assistant.callOpeningMessage?.trim() === "<p><br></p>" ||
                    !assistant.callOpeningMessage?.trim()
                  ? "Assistant's Call Opening Message is required to test"
                  : ""
              }
            >
              <span>
                <Button
                  size="large"
                  variant="contained"
                  startIcon={<PhoneIcon />}
                  onClick={handleTalkToAgentClick}
                  disableElevation
                  disabled={
                    assistant.script?.trim() === "<p><br></p>" ||
                    !assistant.script?.trim() ||
                    !assistant.callOpeningMessage?.trim() ||
                    !assistant.goal?.trim() ||
                    assistant.callOpeningMessage?.trim() === "<p><br></p>"
                  }
                >
                  Test your assistant
                </Button>
              </span>
            </Tooltip>
          )}
        </Grid>
        <Box sx={{ borderBottom: 1, borderColor: "#D3D3D3" }}></Box>
        <CardHeader
          title={
            <Grid
              container
              marginTop={-1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={12} marginBottom={-2}>
                {!fromOnboarding ? (
                  <TabContext value={selectedTab}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleTabChange}
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          "& .css-heg063-MuiTabs-flexContainer": {
                            justifyContent: "center",
                            display: "flex",
                            alignItems: "center",
                          },
                        }}
                        aria-label="Assistant Setup Tabs"
                      >
                        {tabs.map((tab) => (
                          <Tab
                            sx={{
                              minHeight: "40px",
                              width: "calc(1/3 * 100%)",
                            }}
                            key={tab.value}
                            label={tab.label}
                            iconPosition="start"
                            value={tab.value}
                            icon={tab.icon}
                            disabled={fromOnboarding}
                          />
                        ))}
                      </TabList>
                    </Box>
                    {tabs.map((tab) => (
                      <TabPanel
                        style={{ padding: 0, paddingBlock: 15 }}
                        key={tab.value}
                        value={tab.value}
                      >
                        {tab.component}
                      </TabPanel>
                    ))}
                  </TabContext>
                ) : (
                  <TabContext value={selectedTab}>
                    <TabPanel
                      style={{ padding: 0, paddingBlock: 15 }}
                      key={tabs[0].value}
                      value={tabs[0].value}
                    >
                      {tabs[0].component}
                    </TabPanel>
                  </TabContext>
                )}
              </Grid>
            </Grid>
          }
        />
      </Card>

      <Dialog
        PaperProps={{
          sx: {
            overflowY: "visible",
          },
        }}
        aria-labelledby="vg-test-assistant"
        open={
          dialogData.showTestAssistant || dialogData.showVoiceAccent || false
        }
        onClose={handleCloseDialoge}
        fullWidth
      >
        <DialogContent sx={{ overflowY: "visible" }}>
          {dialogData?.showTestAssistant && (
            <ErrorBoundary onErrorCallback={handleCloseDialoge}>
              <TestAssistantForm
                assistantId={assistant.assistantId}
                onCancel={handleCloseDialoge}
              />
            </ErrorBoundary>
          )}
        </DialogContent>
      </Dialog>
      <Dialog
        PaperProps={{
          sx: {
            maxWidth: 1000,
          },
        }}
        aria-labelledby="com-lang-miss-match-force-save"
        open={comForceAllowDialog}
        onClose={handleCOMForceDialogToggle}
        fullWidth
      >
        {" "}
        <IconButton
          aria-label="close"
          onClick={handleCOMForceDialogToggle}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          align="center"
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap={1}
        >
          <WarningIcon color="error" /> Warning
        </DialogTitle>
        <DialogContent>
          <Grid container display="flex" justifyContent="center">
            <Grid item xs={10} mb={1}>
              <Typography
                variant="h6"
                align="center"
                sx={{ fontWeight: "bold" }}
              >
                We recommend you to type your Assistant's Call Opening Message
                in the same language as set for your Assistant, for the best
                possible experience !!
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              mt={4}
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={2}
            >
              <Button
                sx={{ textTransform: "none", minWidth: "100px" }}
                variant="outlined"
                color="primary"
                onClick={handleCOMForceSave}
              >
                Force Save
              </Button>
              <Button
                sx={{ textTransform: "none", minWidth: "100px" }}
                variant="outlined"
                onClick={handleCOMForceDialogToggle}
                color="primary"
              >
                Edit
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AssistantSetup;
